import request from "@/utils/request";

// 关注
export function fetchFocusOn(data) {
  return request({
    url: "/crm/currency/addGz",
    method: "POST",
    data
  });
}

// 取消关注
export function fetchCancelFocusOn(data) {
  return request({
    url: "/crm/currency/deleteGz",
    method: "PUT",
    data
  });
}

// 根据客户id获取客户签约人 （联系人）
export function fetchContactsByCustomerId(params) {
  return request({
    url: "/crm/currency/getContactsListByCustomerId",
    method: "get",
    params
  });
}
