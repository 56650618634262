<template>
  <div>
    <van-steps direction="vertical" class="steps">
      <van-step class="item-step" v-for="item in recordlist" :key="item.id">
        <h3>{{ item.createBy }}</h3>
        <p class="time">{{ item.createTime }}</p>
        <p>{{ item.remark }}</p>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
import { fetchBusinessList } from '@/api/tool/gen';
export default {
  props: {
    businessType: String,
  },
  data() {
    return {
      recordlist: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const result = await fetchBusinessList({
        businessId: this.$route.query.id,
        businessType: this.businessType,
      });
      this.recordlist = result.data || [];
    },
  },
};
</script>
<style lang="scss" scoped>
.steps {
  .item-step {
    h3 {
      line-height: 30px;
      color: #111111;
    }
    .time {
      color: #666666;
    }
    p {
      line-height: 40px;
    }
  }
}
::v-deep .van-step__title--active {
  color: #969799;
}
::v-deep .van-steps--vertical {
  padding: 20px 30px 0 80px;
}
::v-deep .van-step--vertical .van-step__circle-container {
  font-size: 30px;
}
::v-deep .van-step__icon--active {
  color: #2578ff;
}
::v-deep .van-icon-checked:before,
::v-deep .van-step__circle {
  display: inline-block;
  font-size: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #2578ff;
}
::v-deep .van-step--vertical .van-step__circle-container {
  top: 32px;
}
::v-deep .van-step--vertical {
  padding: 20px 30px;
}
</style>
