<template>
    <div class="follow-list">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                class="list"
        >
            <div class="item-list" v-for="item in followList" :key="item.id">
                <div class="flex-spa-ver-cen top">
                    <div class="flex-ver-cen">
                        <img
                                :src="
                require(`../../../assets/images/${typeImgObj[item.followType]}`) ||
                require('../../../assets/images/phone_img.png')
              "
                                class="type-icon"
                                alt=""
                        />
                        <div class="tit mrg-l-10">{{ item.followTypeName }}</div>
                    </div>
                    <div class="time">{{ item.createTime }}</div>
                </div>
                <div class="desc">{{ item.followContent || '--' }}</div>
                <div class="operate">操作者：{{ item.createBy }}</div>
                <div class="operate" v-if="item.address">地点：{{ item.address }}</div>

                <div class="item-list" v-for="annex in item.sysAnnexList" :key="annex.id">
                    <div class="flex-spa-ver-cen top" @click="imgClick(annex.annexUrl)">
                        <div class="flex-ver-cen">
                            <img :src="annex.annexUrl" alt=""/>
                            <div class="file-wrap mrg-l-10">
                                <div class="file-name">{{ annex.annexName }}</div>
                                <div class="file-size mrg-t-10">附件大小： {{ annex.fileSize }}</div>
                            </div>
                        </div>
                    </div>
                    <van-divider/>
                </div>

            </div>
        </van-list>
        <div class="bg"></div>
        <div class="btn" @click="handleAdd">
            <van-icon name="plus" class="mrg-r-10"/>
            新增跟进记录
        </div>
    </div>
</template>
<script>
    import {fetchFollowList} from '@/api/crm/clue';
    import {fetchCustomerFollowList} from '@/api/crm/customer';
    import {fetchCostFollowList} from '@/api/crm/bussiness';
    import {FOLLOW_TYPE_IMG} from '@/views/constant.js';
    import {ImagePreview} from 'vant';

    export default {
        props: {
            pages: String,
        },

        data() {
            return {
                loading: false,
                finished: true,
                pathObj: {
                    clue: '/crm/clue/addFollow',
                    customer: '/crm/customer/addFollow',
                    cost: '/crm/cost/addFollow'
                },
                funObj: {
                    clue: fetchFollowList,
                    customer: fetchCustomerFollowList,
                    cost: fetchCostFollowList,
                },
                followList: [],
                typeImgObj: FOLLOW_TYPE_IMG,
            };
        },

        mounted() {
            this.getFollowList();
        },

        methods: {
            //图片被点击
            imgClick(annexUrl) {
                ImagePreview({images: [annexUrl], closeable: true});
            },

            handleAdd() {
                this.$router.push({
                    path: this.pathObj[this.pages],
                    query: {
                        id: this.$route.query.id,
                    },
                });
            },

            async getFollowList() {
                let dataObj = {
                    clueId: this.$route.query.id,
                    customerId: this.$route.query.id,
                    costId: this.$route.query.id,
                    contactId: this.$route.query.id,
                }; // eslint-disable-line no-unused-vars
                const result = await this.funObj[this.pages](dataObj);
                const {data = []} = result;
                this.followList = data;
            },
        },
    };
</script>
<style lang="scss" scoped>
  .list {
    box-sizing: border-box;
    background: #fff;
  }

  .item-list {
    padding: 0 40px;
    border-top: 20px solid #fafafa;

    .top {
      height: 80px;
      padding-top: 10px;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }

    .tit {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .time {
      font-size: 22px;
      font-weight: 400;
      color: #b5b5b5;
    }

    .desc {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      margin: 20px 0;
    }

    .operate {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      margin: 20px 0;
    }
  }

  .bg {
    margin-bottom: 140px;
    width: 100%;
    height: 90px;
    background: #fafafa;
  }

  .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 750px;
    height: 110px;
    line-height: 110px;
    background: #fff;
    color: #0091ff;
    font-size: 30px;
    text-align: center;
  }

  .file-wrap {
    .file-name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }

    .file-size {
      font-size: 22px;
      color: #aaaaaa;
      margin-top: 10px;
    }
  }
</style>
